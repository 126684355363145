<template>
  <div class="uk-container">
    <notifications group="public" position="bottom center"  :style="{marginBottom:'2px'}"/>
    <v-row class="uk-container">
      <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
        <base-arrow-back-icon @click="$router.go(-1)">{{
          rightArrowIcon
        }}</base-arrow-back-icon>

        <span v-if="isUpdatePage"> تعديل درس </span>
        <span v-if="isPreviewPage"> عرض درس </span>
        <span v-if="isNewPage">إضافة درس جديد</span>
      </h1>
    </v-row>
    <v-row class="uk-container uk-margin-large-top">
      <v-form ref="form" lazy-validation>
        <v-container class="grey lighten-5">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="data.title"
                class="pa-2"
                :readonly="isPreviewPage"
                label="العنوان"
                :rules="[(v) => !!v || 'العنوان مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="data.order"
                type="number"
                class="pa-2"
                :readonly="isPreviewPage"
                label="الترتيب"
                :rules="[(v) => !!v || 'الترتيب مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="data.type"
                class="pa-2"
                :readonly="isPreviewPage"
                :rules="[(v) => !!v || 'النوع مطلوب']"
                :items="[
                  { text: 'فيديو', value: 'video' },
                  { text: 'مستند', value: 'sheet' },
                ]"
                label="النوع"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <form id="lecture">
                <v-file-input
                  class="pa-2"
                  label="الملف"
                  v-if="!isPreviewPage && data.type"
                  name="file"
                  id="file"
                  :accept="[
                    data.type == 'video' ? 'video/*' : 'application/pdf',
                  ]"
                  :rules="[(v) => !!v || 'الملف مطلوب']"
                ></v-file-input>
              </form>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="data.description"
                class="pa-2"
                :rules="[(v) => !!v || 'الشرح مطلوب']"
                label="الشرح"
                :readonly="isPreviewPage"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <div class="text-center" v-if="!isPreviewPage">
          <v-btn color="success" class="mr-4" @click="submit">
            <span v-if="isNewPage"> إدخال</span> <span v-else>تعديل</span>
          </v-btn>
        </div>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";
export default {
  data() {
    return {
      data: {
        title: "",
        order: "",
        type: "",
        description: "",
      },
    };
  },
  components: { BaseArrowBackIcon },
  computed: {
    ...mapGetters({
      lecture: "getLecture",
    }),
    isNewPage() {
      return /new/.test(this.$route.path);
    },
    isUpdatePage() {
      return /update\/[0-9]/.test(this.$route.path);
    },
    isPreviewPage() {
      return /show\/[0-9]/.test(this.$route.path);
    },
  },
  watch: {
    lecture: function () {
      this.data.title = this.lecture.title;
      this.data.order = this.lecture.order;
      this.data.type = this.lecture.type;
      this.data.description = this.lecture.description;
    },
  },
  mounted() {
    if (!this.isNewPage) {
      this.fetchSingleLecture(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions([
      "addNewLectureInstructor",
      "fetchSingleLecture",
      "updateLectureInstructor",
    ]),
    submit() {
      if (!this.$refs.form.validate()) return;
      let formData = new FormData(document.getElementById("lecture"));
      for (let key in this.data) {
        formData.append(key, this.data[key]);
      }
      if (this.isNewPage) {
        formData.append("module_id", this.$route.params.id);
        formData.append("language", "en");
        this.addNewLectureInstructor(formData).then((res) => {
          if(res?.data?.status)
            if (res.data.status === 'success' || res.data.status === 'Success' || res.data.status === 'Successe') {
              this.$router.push({
                name: "lecturesIndex",
                params: { id: this.$route.params.id },
                query: { fromWhere: 'addEdit'}
              });
            }
        });
      } else if (this.isUpdatePage) {
        let data = {
          data: formData,
          lectureId: this.$route.params.id,
        };
        this.updateLectureInstructor(data).then((res) => {
          if(res?.data?.status)
            if (res.data.status === 'success' || res.data.status === 'Success' || res.data.status === 'Successe') {
              this.$router.push({
                name: "lecturesIndex",
                params: { id: this.$route.params.id },
                query: { fromWhere: 'addEdit'}
              });
            }
        });
      }
    },
  },
};
</script>